import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import ReactDOM from 'react-dom'
import '../../node_modules/react-modal-video/css/modal-video.min.css';
import ModalVideo from 'react-modal-video';
require('./blogpost.css')


class FormerSuccess extends React.Component {

  constructor() {
    super();
    this.state = {

      isOpen: false,
    };
    this.openModal = this.openModal.bind(this)
  }

  openModal() {
    this.setState({ isOpen: true })
  }


  render() {
    return (
      <Link
        className="blogMain"
        to={"/blogpost/" + this.props.slug}
      >
        {/* <iframe className="farmerVideoSec" src={this.props.video}
          frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
        </iframe> */}
        <div>
          <img className="formersuccesspic" 
          src={this.props.img} 
          style={{
            width: "500px",
            height: "250px",
            objectFit: "cover"
          }}  
          alt=""></img>
          <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.props.videoid} onClose={() => this.setState({ isOpen: false })} />
          {/* <button onClick={this.openModal}>Open</button> */}
          {/* <a onClick={this.openModal}><img className=
            "videoicon" src="https://res.cloudinary.com/djoztpm50/image/upload/q_80/v1555081101/group-48121_frmfyh.svg"></img>
          </a> */}
        </div>


        <div className="formersuccessInfo">
          <h3 className="FormerBenefits">{this.props.heading}</h3>
          <p className="formerBenInfo">{this.props.content}</p>
        </div>
      </Link>
    );
  }
}

export default FormerSuccess;

